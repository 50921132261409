
.wrapper{
  width:50px;
  height:10px;
  margin-left: 5px;
  margin-top: 8px;
  position: relative;
}
.circle{
  width:5px;
  height:5px;
  position: absolute;
  border-radius: 50%;
  background-color: #fff;
  left:5%;
  transform-origin: 10%;
  animation: circle .5s alternate infinite ease;
}

@keyframes circle{
  0%{
      top:5px;
      height:5px;
      border-radius: 50px 50px 50px 50px;
      transform: scaleX(1.2);
  }
  40%{
      height:5px;
      border-radius: 50%;
      transform: scaleX(1);
  }
  100%{
      top:0%;
  }
}
.circle:nth-child(2){
  left:45%;
  animation-delay: .2s;
}
.circle:nth-child(3){
  left:auto;
  right:0%;
  animation-delay: .3s;
}

.wrapper span{
  position: absolute;
  top:75px;
  font-family: 'Lato';
  font-size: 20px;
  letter-spacing: 12px;
  color: #fff;
  left:15%;
}




